$flexslider__control-nav__active--color: #ff00ff !default;
$flexslider__control-nav__normal--color: #0000ff !default;
$flexslider__control-nav__hover--color: #00ff00 !default;
.flexslider__nav {
  display: flex;

  .flex-direction-nav {
    z-index: 80;
  }

  .flexslider__nav__container {
    position: absolute;
    bottom: 0px;
    z-index: 91;
  }

  .flex-control-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 30px;
    width: 100vw;
    align-self: flex-end;
    z-index: 90;

    li {
      width: 25px;
      text-align: center;
      margin: 0 6px;
      display: inline-block;
      zoom: 1;
      *display: inline;

      .flex-active {
        color: $flexslider__control-nav__active--color;
        i {
          font-weight: 900;
        }
      }

      a {
        width: 25px;
        text-align: center;
        color: $flexslider__control-nav__normal--color;
      }

      a:hover {
        color: $flexslider__control-nav__hover--color;
      }
    }
  }
}

.flexslider {
  .slides {
    .slidercontext {
      position: absolute;
      z-index: 9999;
      top: calc(50% - 90px);
      right: 50px;
      width: 580px;
      text-align: left;
      height: 90px;
      opacity: 0;
      transform: translateX(100px);
      transition: opacity 0.2s ease-in-out 0s, transform 1.6s ease-in-out 0s;
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
      h2 {
        text-align: left;
      }

      h3 {
        text-align: left;
        padding: 0px;
      }
      h4 {
        text-align: left;
        color: $body--font-color;
        font-family: $body--font-family;
        padding: 0px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .slidercontext {
      width: calc(100% - 20px) !important;
      right: 10px !important;

      h2 {
        font-size: 30px !important;
        padding-bottom: 0px !important;
        line-height: 32px !important;
      }

      h3 {
        font-size: 17px !important;
      }

      h4 {
        font-size: 13px !important;
      }
    }
  }
}

.flex-active-slide {
  .slidercontext {
    opacity: 1 !important;
    transform: translateX(0px) !important;
  }
}