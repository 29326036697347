@import "import";

//noinspection CssUnknownTarget
//@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700,900');
//@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700,900');

@import "fonts";


/**
  FARBEN
 */

$pink: #1a3e70;
$pinkHover: rgba(218, 108, 163, 0.85);
$dunkel-braun: #0263a8;
$hell-braunHover: $dunkel-braun;
$hell-braun: #0263a8;


/**
  GENERAL
 */

$body--font-family: 'PT Sans Narrow';
$body--font-size: 20px;
$body--font-size-xs: 16px;
$body--font-weight: 200;
$body--font-color: #333;

$h1--color: #e20613;
$h1--font-family: 'PT Sans Narrow';
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 45px;
$h1--font-weight: 200;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 1px 1px 0px #fff;


$h2--color: $h1--color;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-size-xs: 25px;
$h2--font-weight: $h1--font-weight;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;
$h2--text-transform: uppercase;

$h3--color: #7d7d7d;
$h3--font-family: 'PT Sans Narrow';
$h3--font-size: 24px;
$h3--font-size-xs: 17px;
$h3--font-weight: 700;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: 1px 1px 0px #fff;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$main--font-size-xs: 14px !important;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: none;
$header--height: 150px;

$header__top-area__row--bg: none;
$header__top-area--height: 40px;
$header__bottom-area__nav--bg: none;
$header__top-area__svg--color: $header__top-area__row--bg;
$header__logo--bg: url(../images/logo_head_bg.png) !important;
$header__logo--width: 150px;
$header__logo--height: 140px;
$header__logo--bg-size: 100% !important;

$header__bottom-area__nav--bg: none;

$main--padding: $header--height 0px 0px 0px;


$nav__ul__li__a--font-family: 'PT Sans Narrow';
$nav__ul__li__a--color: #fff;
$nav__ul__li__a--link-hover-color: #a2c7e1 !important;
$nav__ul__li__a--font-size: 30px;
$nav__ul__li__a--font-weight: 700;
$nav__ul__li__a--text-shadow: 0.5px 0.5px 0px #051731;


$nav__ul__li__dropdown--bg: rgba(255, 255, 255, 0.75);
$nav__ul__li__dropdown__a--font-family: 'PT Sans Narrow';
$nav__ul__li__dropdown__a--font-weight: 300;
$nav__ul__li__dropdown__a--font-size: 18px;
$nav__ul__li__dropdown__a--color: #321c0c !important;
$nav__ul__li__dropdown__a--link-hover-color: #977351 !important;
$nav__ul__li__dropdown__a--link-hover-bg: rgba(255,255,255,0.5) !important;
$nav__ul__li__dropdown__a--text-align: center !important;

$nav__ul__active__a--color: #a2c7e1;

$navbar-default-link-color: #da6ca3;
$navbar-link-hover-color: #321c0c;

$flexslider__control-nav__active--color: #da6ca3;
$flexslider__control-nav__normal--color: #321c0c;
$flexslider__control-nav__hover--color: #d3b395;

$main__a--color: $dunkel-braun;
$main__a__hover--color: $hell-braun;


$hline-border-top: 1px solid #15325a;
$hline-border-bottom: 1px solid #31527f;



$footer__h2--color: #fff;
$footer__h2--font-family: 'PT Sans Narrow';
$footer__h2--font-size: $h2--font-size;
$footer__h2--font-weight: $h2--font-weight;
$footer__h2--line-height: $h2--line-height;
$footer__h2--text-rendering: geometricPrecision;
$footer__h2--text-shadow: 0 -0.5px #a0376c;


$footer__h3--color: #fff;
$footer__h3--font-family: $h3--font-family;
$footer__h3--font-weight: $h3--font-weight;

$footer__h3--font-size: $h3--font-size;
$footer__h3--text-shadow: 0 -0.5px #a0376c;

$footer__a--color: #ffffff;
$footer__a__hover--color: #a2c7e1;

$footer--font-family: 'PT Sans Narrow';
$footer--font-weight: 300;
$footer--font-size: 15px;
$footer--padding: 40px 0px 0px 0px;

$footer--bg: $pink;
$footer__balken-bottom--background: $dunkel-braun;
$footer__balken-bottom--color: #102e58;
$footer__balken-bottom--font-family: 'PT Sans Narrow';
$footer__balken-bottom--text-shadow: 0px -0.5px 0px #150a02;
$footer__balken-bottom--font-size: 20px;
$footer__balken-bottom--text-shadow: 0px -0.5px 0px #4f92c1;

$footer__balken-bottom2--padding: 10px;
$footer__balken-bottom2--background: $hell-braun;


$linkboxen--box-shadow: 0px 0px 20px rgba(50, 28, 12, 0.1);
$linkboxen__name--background: rgba(255, 255, 255, 0.7);
$linkboxen__imgdiv--border: 1px solid #fcfbf7;
$linkboxen__a--color: $pink;
$linkboxen__a--font-family: 'PT Sans Narrow';
$linkboxen__a--font-size: 30px;
$linkboxen__a--line-height: 50px;
$linkboxen__a--font-weight: 300;

$linkboxen__a-hover--font-size: 40px !default;
$linkboxen__a-hover--line-height: 60px !default;


$rexshop__list--background: rgba(255,255,255,0.5);
$rexshop__list--font-family: 'PT Sans Narrow';
$rexshop__list--color: $pink;
$rexshop__list--font-size: 30px;
$rexshop__list--line-height: 50px;
$rexshop__list--font-weight: 300;
$rexshop__list__article__preis--color: $dunkel-braun;
$rexshop__list__article__preis--font-weight: bold;

/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
@import "header/navigation";
@import "header/header";
@import "header/logo";
@import "content/content";
@import "general/schatten";
@import "general/footer";
@import "teaser/teaser";
@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts';

@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
@import "modules/link-box";
@import "modules/rexshop";
@import "modules/team";
@import "modules/verlinkung";

@import "../css/test.css";


$team-width: 25%;

h4 {
  font-family: 'PT Sans';
  font-size: 21px!important;
  color: #e20613;
}
.team_kurztext {
  height: 100%;
  display: flex;
  flex-direction: column;;
  .abteilung {
    font-size: 15px;
  }
}

.team_foto {
  h4 {
    margin: 10px 0px 0px 0px;
    padding: 0px;
  }
}

.team_infos {
  flex-grow: 1;
}

.team_kurztext {
  i {
    font-size: 50px;
    color: $dunkel-braun;
  }
}

.header {
  box-shadow: none;
}

.header__top-area__row {
  background: $header__top-area__row--bg;
  height: $header__top-area--height;
  width: calc(100%);
}

.header__bottom-area {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%) translateY(0%) !important;

  nav {
    transform: translateY(0%);
    border-radius: 5px;

    ul {
      border-radius: 5px;
      text-transform: uppercase;
    }
  }
}

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

.textblock-team {
  display: flex;
}

article {
  padding: 20px;
}

.linkboxen {
  margin-top: 40px;
  .linkbox__name {
    div {
      text-shadow: 0.5px 0.5px 0px #fff;
    }
  }
}

#main__content {
  background: none;
  padding-top: 1px;
  position: relative;
}

.footer__icons {
  font-size: 60px;
  display: flex;

  a {
    flex-grow: 1;
    text-align: right;

    i {
      margin-right: -13px;
    }
  }
}

.balken-bottom {
  a:link {
   font-weight: normal;
  }
  a:hover {
    color: #fff;
    text-shadow: none;
  }
}

body {
  margin-top: -4px;
  font-size: 17px;
  background: #e5e5e5;
  color: $body--font-color;
  overflow-x: hidden;
}

.logo-mobile {
  display: none;
}


@include media-breakpoint-down(md) {
  .team--listing .team {
    width: 100%;
  }
  .header {
    height: 100px !important;

    .logo {
      height: auto;
      bottom: -28px;
      position: absolute;
      top: unset;
      left: 74px;
      img {
        width: 101px;
      }
    }
    .header__top-area {
      .header__top-area__icons {
        top: 8px !important;
        div {
          a {
            i {
              color: $dunkel-braun;
            }
          }
        }
      }
    }
    .header__bottom-area {
      top: 0px !important;
      background: $pink;
      padding: 10px;
      box-shadow: 0px 0px 10px #000;
      max-width: 992px;
    }
    .logo-bg {
      display: none;
      opacity: 0;
    }
  }
  .balken-grau {
    display: none;
    opacity: 0;
  }
  .header>.logo img {
    width: 180px !important;
    top: 5px !important;
    position: fixed;
    transform: translateX(20px);
    left: 0px;
  }

  #main .main__teaser__tease .embed-responsive {
    padding-top: 130px;
  }

  .slides li {
    padding-top: 90px !important;
    padding-bottom: 27px !important;
    .optimizedpicture {
      z-index: 999;
    }
  }

  .main__content__ajax>article {
    padding-top: 10px !important;
  }

  .main__teaser {
    width: 110% !important;
  }

  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
  }
}


.rexshop__list__article--title {
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: rgba(255,255,255, 0.7);
}

.rexshop__list__article--inhaltsstoffe {
  font-size: 12px;
}

.shoppingcart--items {
  position: absolute;
  top: 16px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  line-height: 5px;
  font-size: 11px;
}

.navbar-light .navbar-nav .nav-link {
  text-shadow: -0.5px -0.5px 0px rgba(5, 23, 49, 0.5);
}

h2, h3 {
  text-shadow: 0.5px 0.5px 0px #ffffff !important;
}
footer {
  text-shadow: -0.5px -0.5 0px #051731 !important;
  font-size: 20px;
  a {
    text-shadow: -0.5px -0.5px 0px #051731 !important;
    font-size: 20px;
  }

  h3 {
    text-shadow: -0.5px -0.5px 0px #051731 !important;
  }

  .balken-bottom {
    a {
      color: #102e58;
      text-shadow: 0.5px 0.5px 0px #4f92c1 !important;
    }
    text-shadow: 0.5px 0.5px 0px #4f92c1;
  }

  .container {
    .row {
      div {
        article {
          padding-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }
}

.main__content__ajax {
  padding-top: 60px;
  >article,
  >div {
    padding-top: 0px;
  }
  > .container > *,
  > .container-fluid {
    .shadow-top {
      bottom: 0px;
      position: relative;
      margin-top: 30px;
      .devider-color {
        position: relative;
        width: 100%;
        height: 1px;
        background: #cccccc;
      }
    }
    .shadow-bottom {
      display: none;
      .devider-color {
        position: relative;
        width: 100%;
        height: 1px;
        background: #cccccc;
      }
    }
    @include media-breakpoint-up(lg) {
      > * {
        opacity: 0;
        transform: translateY(20px) rotate(-2deg);
        transform-origin: -100% 0;
        transition: opacity 1s ease-in-out 0s, transform 1s ease-in-out 0s, -webkit-transform 1s cubic-bezier(0.28, 0.36, 0, 2.75) 0s;
      }
    }
  }
}

.fadeContentIn {
  opacity: 1 !important;
  transform-origin: left top;
  transform: translateY(0px) rotate(0deg) !important;
}

.main__content__ajax .no-fade {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.modal-title {
  font-family: $h3--font-family;
  font-weight: $h3--font-weight;
  font-size: $h3--font-size;
  color: $h3--color;
  line-height: $h3--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .modal-dialog {
    max-width: 560px;
  }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {

}
.btn-primary {
  background: $pink;
  border: 0px;
}

.table td, .table th {
  border-top: 1px solid rgba(255,255,255, 0.5);
}

/**
 * LAZYLOAD
 */
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  max-width: unset !important;
  max-height: unset !important;
  top: -5px;
  left: -5px;
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s, filter 0.3s;
  transition-timing-function: ease-in-out;
  position: absolute
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  left: 0px;
}

#addedToCart {

  .modal-body {
    justify-content: center;
    display: flex;
  }
  .modal-footer {
    justify-content: space-between;
  }
}

.rexshop__list__article--preis {
  font-family: $h2--font-family;
  font-weight: $h2--font-weight !important;
  font-size: $h2--font-size;
  color: $h2--color !important;
  line-height: $h2--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
  span {
    font-size: 12px;
    font-family: $body--font-family;
    color: $body--font-color !important;
  }
}

.cc-window {
  font-family: $body--font-family !important;
}


/**
 *
 */

#main {
  padding: 0px;
  .main__teaser__tease {
    .embed-responsive {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
  }
}

.navbar-light .navbar-toggler {
  border-color: #fff;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.logo-bg {
  background: #1a3e70;
  width: 400%;
  height: 400%;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-48deg) translate(0px, -90%);
}

.header>.logo {
  width: 12vw;
  height: 8vw;
  img {
    z-index: 999;
    width: unset;
  }
}

.slides .optimizedpicture {
  object-fit: cover;
  max-width: unset !important;
  max-height: unset !important;
}

.balken-grau {
  width: 300vw;
  height: 100%;
  background: rgba(0,0,0,0.1);
  z-index: 50;
  position: fixed;
  transform: rotate(-48deg) translate(0px, -22vw);
  left: -100vw;
  top: 0px;
}


.main__teaser {
  transform: rotate(-2deg);
  width: 102% !important;
  left: -1% !important;
  top: -20px !important;
}
.main__teaser__tease {
  .flexslider .slides li .optimizedpicture {
    bottom: -35px;
    transform: rotate(2deg);
  }
}

.slides {
  li {
    max-width: 1500px;
    padding-top: 120px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $nav__ul__li__a--color;
}

.referenzen {
  display: flex;
  flex-wrap: wrap;
  .refcontainer {
    display: flex;
  }
  .ref-col-1 { width: calc(100%/1); }
  .ref-col-2 { width: calc(100%/2); }
  .ref-col-3 { width: calc(100%/3); }
  .ref-col-4 { width: calc(100%/4); }
  .ref-col-5 { width: calc(100%/5); }
  .ref-col-6 { width: calc(100%/6); }
  .ref-col-7 { width: calc(100%/7); }
  .ref-col-8 { width: calc(100%/8); }
  .ref-col-9 { width: calc(100%/9); }
  .ref-col-10 { width: calc(100%/10); }
  .ref-col-11 { width: calc(100%/11); }
  .ref-col-12 { width: calc(100%/12); }

  @include media-breakpoint-down(md) {
    .ref-col-1, .ref-col-2, .ref-col-3, .ref-col-4, .ref-col-5, .ref-col-6,.ref-col-7,.ref-col-8,.ref-col-9,.ref-col-10,.ref-col-11,.ref-col-12 {
      width: 100% !important;
      .referenz {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
    }
  }

  .referenz {
    margin-bottom: 2%;
    width: 100%;
    font-size: 16px;
    ul {
      padding-left: 20px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .referenz {
    .bild:hover {
      filter: unquote("grayscale(0%)!important");
    }

    .bild {
      filter: unquote("grayscale(100%)");
    }
  }
}

.ref-slides {
  display: flex;
  flex-direction: column;
  padding-top: 75%;

  .active {
    display: block;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 99999;
  }
  .ref-hide {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    opacity: 0;
    z-index: 999;
  }
  div {
    max-width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .ref-slides {
    div {
      transition: opacity 1.2s;
    }
  }
}

.navbar button:focus {
  outline: 0px !important;
}

.bild_text__box {
  margin-bottom: 5px !important;
}

.bild_text__box, .referenz {
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  margin: 20px;
  width: 100%;
  .bild {
    position: relative;
    .flexslider {
      display: flex;
      ul{
        position: relative;
        width: 100%;

        li {
          padding-top: 0px;
        }
      }
    }
  }
  h3 {
    color: $h1--color;
    font-size: 25px;
    margin-top: 15px;
    font-family: 'PT Sans';
  }
  color: #555555;
  p {
    margin-bottom: 0px;
  }
}

#main__content {
  .bild_text_box, .referenz {
    .bild {
      img {
        filter: unquote("grayscale(0%) !important");
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .optimizedpicture {
      img {
        filter: unquote("grayscale(100%) !important");
        transition: all 0.35s;
      }

      img:hover {
        filter: unquote("grayscale(0%) !important");
      }
    }
  }
}


@include media-breakpoint-down(md) {
  h2 {
    font-size: 35px !important;
  }
  h3 {
    font-size: 21px !important;
  }

  img {
    filter: unquote("grayscale(0%)!important");
  }

  .referenz {
    .bild:hover {
      filter: unquote("grayscale(0%)!important");
    }

    .bild {
      filter: unquote("grayscale(0%)");
    }
  }

  #main__content {
    .main__content__ajax {
      padding-top: 0px;

      .container-fluid>* {
        padding-left: 0px;
        padding-right: 0px;
        opacity: 1.0 !important;

      }
    }
  }
}


.navbar-toggler-balken-1,
.navbar-toggler-balken-2,
.navbar-toggler-balken-3 {
  width: 30px;
  border-bottom: 1px solid #fff;
  height: 10px;
}

.navbar-light .navbar-toggler {
  border: 0px;
}

.navbar-toggler {
  .navbar-toggler-balken-1 {
    transform: rotate(45deg);
    transform-origin: 6px 16px;
    transition: all 1s;
  }

  .navbar-toggler-balken-3 {
    transform: rotate(-45deg);
    transform-origin: 0 0px;
    transition: all 1s;
  }

  .navbar-toggler-balken-2 {
    opacity: 0;
    transition: all 0.5s;
  }
}

.collapsed {
  .navbar-toggler-balken-1 {
    transform: rotate(0deg);
    transition: all 1s;
  }

  .navbar-toggler-balken-3 {
    transform: rotate(0deg);
    transition: all 1s;
  }

  .navbar-toggler-balken-2 {
    opacity: 1;
    transition: all 0.5s;
  }
}

.shadow-teaser-bottom {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: -20px;
  transform: translateY(-100%) rotate(-2deg);
  z-index: 999;
  position: absolute;
}

.allgemeineinfos li a i {
  width: 25px;
}

.flexslider__nav {
  display: none;
  opacity: 0;
}

.textblock-bild_text {
  display: flex;
}

strong {
  font-weight: bold;
}

.navfixedtop {
  background: rgba(240,240,240,1);
  box-shadow: 0px 0px 5px rgba(0,0,0,1);
  top: 0px;
  .navbar-nav {
    .nav-link {
      color: rgb(26, 62, 112);
      text-shadow: rgba(255, 255, 255, 0.5) -0.5px -0.5px 0px !important;
    }
  }
}

.navfixedtopparent {
  top: 0px;
}

.optimizedpicture {
  position: relative;
}

.shadow .optimizedpicture {
  max-width: unset !important;
}

@media screen and (min-width: 2400px) and (-webkit-max-device-pixel-ratio: 1) {
  .container {
    max-width: 1920px;
  }
  .textblock-image .optimizedpicture {
    max-width: 100% !important;
  }

  .main__teaser {
    top: -120px !important;
  }

  .main__teaser__tease {
    height: 1000px;
  }

  .header>.logo {
    height: 9vw;
    width: 9vw;
  }

  .main__teaser {
    top: -60px;
  }

  .slides li {
    padding-top: 330px;
    max-width: 2000px !important;
  }

  #main__content {
    top: -100px;
  }
  h2 {
    font-size: 54px;
  }
  h3 {
    font-size: 32px !important;
  }
  h4 {
    font-size: 28px !important;
  }
  main {
    font-size: 24px;
  }
  .team .team_kurztext {
    font-size: 22px;
  }
}