.verlinkung__box {
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  margin: 20px;
  height: calc(100% - 25px);
  flex-direction: column;
  display: flex;
  .bild {
    position: relative;
    filter: unquote("grayscale(100%)");
    transition: all 0.35s;
    .flexslider {
      display: flex;
      ul{
        position: relative;
        width: 100%;

        li {
          padding-top: 0px;
        }
      }
    }
  }

  .link-row {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .text {
      flex-grow: 1;
    }
    .linkin {
      display: flex;
      .hr {
        width: 100%;
        position: relative;
        height: 1px;
        background: #cccccc;
        margin-top: 25px;
      }
      a {
        background: #0263a8;
        i {
          font-size: 40px;
          color: #fff;
          padding: 5px;
        }
      }
    }
  }

  .bild:hover {
    filter: unquote("grayscale(0%) !important");
  }
  h3 {
    color: $h1--color;
    font-size: 25px;
    margin-top: 15px;
    font-family: 'PT Sans';
  }
  color: #555555;
  p {
    margin-bottom: 0px;
  }
}