$team-margin: 10px !default;
$team-width: 33% !default;
$team--media-grayscale: true !default;
$team--headline-margin: 10px 0 !default;
$team--kurztext-text-align: center !default;
$team--kurztext-font-size: 16px !default;

@include listing-box('team',row,center,stretch,wrap) {
  margin: $team-margin;
  $team-calc-margin: $team-margin * 2;
  width: unquote("calc(#{$team-width} - #{$team-calc-margin})");

  display: flex;
  flex-direction:column;
  justify-content: space-between;

  @include magicalImageContainer;

  @if $team--media-grayscale == true {
      img {
      @include magicalGrayscale;
    }
  }

  @include headline {
    margin: $team--headline-margin;
    color: $font-color-h1;
  }
}

.team {
  margin: 10px;
  width: calc(100% - 20px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,.25);
  background: #fff;

  .team_foto {
    width: calc(100%);
    img {
      width: calc(100%);
    }
  }

  .team_kurztext {
    text-align: $team--kurztext-text-align;
    font-size: $team--kurztext-font-size;
    a {
      color: $font-color-h2;
    }
  }
  .kontakt {
    text-align: center;
  }
}


@include media-breakpoint-up(xs) {
  .team {
    max-width: calc(100%);
  }
}